import { useLifecycleStore } from "@/stores/lifecycle";
import { useStoryVersionStore } from "@/stores/storyVersion";
import { get } from "~/api/main";

export default defineNuxtPlugin(async () => {
  const lifecycleStore = useLifecycleStore();

  const route = useRoute();
  if (route.name === "xxxxxx") {
    // Don't setup lifecycle for certain routes
  } else {
    // console.info("Setting up lifecycle...");
    await setupStoryVersion();
  }

  lifecycleStore.setInitialized(true);
});

const setupStoryVersion = async () => {
  const storyVersionStore = useStoryVersionStore();
  // console.info("Retrieving story version...");
  const storedStoryVersion = storyVersionStore.storyVersion;
  if (!storedStoryVersion?.public_id) {
    const storyVersion = await get.publishedStoryVersion("wg3m");
    storyVersionStore.setStoryVersion(storyVersion);
  }
  // console.info(
  //   "Retrieved story version:",
  //   storyVersionStore.storyVersion.public_id
  // );
};
